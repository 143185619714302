import { VARS } from 'config';
import useSWRImmutable from 'swr/immutable';

export function useLocations() {
  const { data, error, isLoading } = useSWRImmutable('GET_LOCATIONS', getLocations);

  return {
    locations: data,
    isLoading,
    isError: error,
  };
}

const getLocations = async () => {
  const response = await fetch(`${VARS.apiUrl}/api/public/locations`);
  const { data: locations } = await response.json();

  if (!Array.isArray(locations)) return [];

  const data = locations
    .map((location) => {
      if (location.value.city === 'U//N' || location.value.country === 'U//N') {
        return null;
      }

      return {
        elId: location.key,
        value: `${location.value.city}, ${location.value.country}`,
        regionId: location.value.regionId,
      };
    })
    .filter((location) => location)
    .sort((a, b) => (a.value < b.value ? -1 : 1));

  return data;
};
