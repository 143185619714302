import cn from 'classnames';
import { TableOfContents } from 'components/common/table-of-contents';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './privacyPolicy.scss';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { hash } = useLocation();

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-inner container">
        <TableOfContents sections={sections}>
          <a href="#privacy-notice" className={cn({ active: hash === '#privacy-notice' })}>
            <strong>Privacy Notice</strong>
          </a>
        </TableOfContents>

        <div className="privacy-policy-content">
          <h1 id="privacy-notice">Privacy Notice</h1>
          <p>
            We at BrightSource Data Analytics Ltd. ("<strong>BrightSource</strong>", "
            <strong>us</strong>", "<strong>we</strong>", or "<strong>our</strong>") recognize and
            respect the importance of maintaining your privacy. This Privacy Notice describes the
            types of information we collect from you when you use our Platform and/or Services (both
            as defined in the Terms of Service, available at{' '}
            <Link to="/terms-of-use">https://brightsource.com/terms-of-use</Link> ("
            <strong>Terms</strong>")). This Privacy Notice also explains how we process, transfer,
            store, and disclose the information collected, as well as your ability to control
            certain uses of the collected information. If not otherwise defined herein, capitalized
            terms have the meaning given to them in the Terms.
          </p>
          <p>
            If you are an individual located in the European Union ("<strong>EU Individual</strong>
            "), some additional terms and rights may apply to you, as detailed herein. BrightSource
            is the data controller in respect of the processing activities outlined in this Privacy
            Notice. Our registered office is Pinchas Rosen 72, Tel Aviv, Israel, and our
            registration number is 515417806.
          </p>
          <p>
            "<strong>Personal Data</strong>" means any information that refers, is related to, or is
            associated with an identified or identifiable individual or as otherwise may be defined
            by applicable law. This Privacy Notice details which Personal Data is collected by us in
            connection with the provision of the Services.
          </p>

          <h2>
            <u>Privacy Notice Key Points</u>
          </h2>
          <p>
            The key points listed below are presented in further detail throughout this Privacy
            Notice. You can click on the headers in this section in order to find out more
            information about any topic. These key points do not substitute the full Privacy Notice.
          </p>

          <ol className="privacy-policy-agenda">
            <li>
              <a href="#personal-data">Personal Data We Collect, Uses and Legal Basis.</a> We
              collect certain Personal Data that you provide to us including registration
              information, payment information, contact information, and any materials or further
              information you upload, such as professional history or CVs. We also collect certain
              Personal Data automatically when you use the Platform and/or Services, including,
              subject to your consent, geo-location. We use your Personal Data for various reasons,
              including to provide you with the Platform and Services, improve our Services, and to
              contact you with marketing offers. These processing activities are based on different
              legal bases including performance of a contract and legitimate interests.
            </li>

            <li>
              <a href="#additional-uses">Additional Uses.</a> We may compile statistical information
              based on anonymous and aggregated data we collect in order to help us understand
              customer needs. In addition, we use certain Personal Data for direct marketing
              purposes.
            </li>

            <li>
              <a href="#sharing-settings">Sharing Settings on the Platform.</a> You have control
              over how your data is shared with others on the Platform and should make sure that
              your settings are configured in accordance with your preferences.
            </li>

            <li>
              <a href="#sharing-the-personal">Sharing the Personal Data We Collect.</a> We share the
              Personal Data we collect with our service providers and subcontractors who assist us
              in the operation of the Platform and process the information on our behalf and under
              our instructions.
            </li>

            <li>
              <a href="#international-transfer">International Transfer.</a> Some of our service
              providers and subcontractors, who have access to your Personal Data are located in
              countries other than your own. We will ensure that we have agreements in place with
              such parties that ensure the same level of privacy and data protection as set forth in
              this Privacy Notice.
            </li>

            <li>
              <a href="#security">Security.</a> We implement measures aimed at protecting your
              Personal Data, but they do not provide absolute information security. Such measures
              include physical, electronic, and procedural safeguards (such as secure servers,
              firewalls, antivirus and SSL encryption), access control, and other internal security
              policies.
            </li>

            <li>
              <a href="#your-rights">Your Rights.</a> Subject to applicable law and in addition to
              other rights as set forth below, you may have a right to access, update, delete,
              and/or obtain a copy of the Personal Data we have collected about you. You have the
              right to withdraw your consent to processing, if provided, at any time by contacting
              us at <a href="mailto:info@brightsource.com">info@brightsource.com</a>.
            </li>

            <li>
              <a href="#data-retention">Data Retention.</a> We retain Personal Data for as long as
              necessary for the purposes set forth in this Privacy Notice. We consider a number of
              different factors when determining the appropriate retention periods.
            </li>

            <li>
              <a href="#cookies-and-similar-technologies">Cookies and Similar Technologies.</a> We
              use cookies and similar technologies to help personalize your experience by helping
              save your settings and customizations across visits and providing you targeted content
              and advertisements. You can adjust your settings to determine which cookies are
              allowed, though this may affect the Services.
            </li>

            <li>
              <a href="#third-party-applications-and-services">
                Third-Party Applications and Services.
              </a>{' '}
              All use of third-party applications or services is at your own risk and subject to
              such third party's privacy policies.
            </li>

            <li>
              <a href="#communications">Communications.</a> We may send you e-mail or other messages
              about us or our Services. You will have the opportunity to opt-out of receiving
              certain messages that are not service-related.
            </li>

            <li>
              <a href="#children">Children.</a> We do not knowingly collect Personal Data from
              children under the age of sixteen (16).
            </li>

            <li>
              <a href="#changes-to-the-privacy-notice">Changes to the Privacy Notice.</a> We may
              change this Privacy Notice from time to time and shall notify you of such changes by
              indicating on the Platform that the Privacy Notice has been amended and by publishing
              an updated Privacy Notice on the Platform.
            </li>

            <li>
              <a href="#comments-and-questions">Comments and Questions.</a> If you have any comments
              or questions about this Privacy Notice, or if you wish to exercise your legal rights
              with respect to your Personal Data, please contact us at{' '}
              <a href="mailto:info@brightsource.com">info@brightsource.com</a>.
            </li>
          </ol>

          <h2 id="personal-data">
            1. <u>Personal Data We Collect, Uses and Legal Basis.</u>
          </h2>

          <p>
            Depending on your usage, we collect different types of data and we and any of our
            third-party sub-contractors and service providers use the data we collect for different
            purposes, as specified below. It is your voluntary decision whether to provide us with
            certain Personal Data, but if you refuse to provide such Personal Data we may not be
            able to register you to the Platform and/or provide you with the Services or part
            thereof.
          </p>

          <p>
            <strong>Registration Data</strong> - In order to use our Platform and/or receive related
            following Personal Data: your full name, email address, password, and certain details
            related to your profession. If you use the Services by logging in through a third-party
            login/account registration service (such as Facebook, Gmail, LinkedIn, or any other
            account), we also receive Personal Data about you as provided by such third-party
            service, such as your personal and professional data that is available on your profile.
            If you are using the Services as an Authorized User on behalf of a Company, certain
            registration information may be provided by an Administrator of the Company's account.
          </p>

          <p>
            <u>How we use this data:</u> (1) To provide you with the Platform and/or Services and to
            respond to your inquiries and requests and to contact and communicate with you; and (2)
            to prevent fraud, protect the security of and address any problems with the Platform,
            and (3) to provide you with informational newsletters and promotional materials relating
            to our Platform and Services, including via email. For more information about our direct
            marketing activities and how you can control your preferences, please see the Direct
            Marketing section below.
          </p>

          <p>
            <u>Legal Basis:</u> (1) We process this Personal Data for the purpose of providing the
            Services to you, which is considered performance of a contract with you, including
            responding to your inquiries and requests and providing customer support. (2) When we
            process your Personal Data for the purposes of preventing fraud, protecting the security
            of and/or addressing problems with the Platform and Services and/or for the purpose of
            providing you with informational newsletters and promotional materials relating to our
            Platform and Services, such processing is based on our legitimate interests.
          </p>

          <p>
            <u>How we use this data</u>: To provide you with the Platform and/or Services.
          </p>

          <p>
            <u>Legal Basis</u>: We process this Personal Data for the purpose of performance of a
            contract with you.
          </p>

          <p>
            <strong>Contact Information</strong> - When you request information from us, sign up for
            our newsletter or contact us for any other reason, we will collect any data you provide,
            such as your full name, phone number, email address, position, and/or your LinkedIn URL,
            the content of your inquiry and any document you may wish to upload to the message.
          </p>

          <p>
            <u>How we use this data</u>: To respond to your request or inquiry, to provide you with
            newsletters and for retargeting purposes.
          </p>
          <p>
            <u>Legal Basis</u>: We process this Personal Data based on performance of a contract
            when we respond to your inquiry and provide you with newsletters. Processing your
            Personal Data for retargeting purposes is based on our legitimate interests.
          </p>

          <p>
            <strong>Automatically Collected Data</strong> - When you visit the Platform, we
            automatically collect information about your computer or mobile device, including
            non-Personal Data such as your operating system, device type, and browser type, and
            Personal Data such as IP address, device ID, language preferences, information about
            your viewing and browsing history, the site that referred you and sites that you may
            visit after leaving our Platform. For more information about the cookies and similar
            technologies we use and how to adjust your preferences, please see the section "Cookies
            and Similar Technologies" below.
          </p>

          <p>
            <u>How we use this data</u>: (1) To review usage and operations, including in an
            aggregated non-specific analytical manner, develop new products or services and improve
            current content, products, and Services; (2) To prevent fraud, protect the security of
            our Platform and Services, and address any problems with the Platform and/or Services.
          </p>

          <p>
            <u>Legal Basis</u>: We process this Personal Data for our legitimate interests to
            develop and improve our products and Services, review usage, perform analytics, prevent
            fraud, for our recordkeeping and protection of our legal rights.
          </p>

          <p>
            <strong>Geo-location</strong> - Subject to your consent, when you use the Platform, we
            collect your (geo)location.
          </p>

          <p>
            <u>How we use this data</u>. We use this information in order to provide you with the
            location-based Services through the Platform.
          </p>

          <p>
            <u>Legal Basis</u>. We process this Personal Data based on your consent. You may
            withdraw your consent at any point by adjusting your settings within the Platform.
          </p>

          <h2 id="additional-uses">
            2. <u>Additional Uses.</u>
          </h2>

          <p>
            2.1. <u>Statistical Information and Analytics.</u> We and/or our service providers use
            analytics tools, including "Google Analytics" to collect and analyze information about
            the use of the Platform and/or Services, such as how often users visit the Platform,
            what pages they visit when they do so, and what other sites and mobile applications they
            used prior to visiting the Platform. By analyzing the information we receive, we may
            compile statistical information across a variety of platforms and users, which helps us
            improve our Platform and Services, understand trends and customer needs and consider new
            products and services, and tailor existing products and services to customer desires.
            You can find more information about how Google collects information and how you can
            control such use at{' '}
            <a href="https://policies.google.com/technologies/partner-sites">
              https://policies.google.com/technologies/partner-sites.
            </a>
          </p>

          <p>
            2.2. <u>Direct Marketing</u>. As described above, we may use Personal Data to let you
            know about our products and Services that we believe will be of interest to you. We may
            contact you by email, post, or telephone or through other communication channels. In all
            cases, we will respect your preferences for how you would like us to manage marketing
            activity with respect to you. To protect privacy rights and to ensure you have control
            over how we manage marketing with you:
          </p>

          <div className="sub-list">
            <p>
              2.2.1. We will take steps to limit direct marketing to a reasonable and proportionate
              level and only send you communications which we believe may be of interest or
              relevance to you.
            </p>

            <p>
              2.2.2. At any time you can update or correct your personal profile within your
              account, or change your preferences for the way in which you would like us to
              communicate with you, including how you receive details of latest offers or
              newsletters from us.
            </p>

            <p>
              2.2.3. You can ask us to stop sending email marketing by following the "unsubscribe"
              link you will find on all the email marketing messages we send you. Alternatively, you
              can contact us at <a href="info@brightsource.com">info@brightsource.com.</a>
            </p>

            <p>
              2.2.4. You can change the way your browser manages cookies, which may be used to
              deliver online advertising, by following the settings on your browser as explained
              below. If our marketing activities are based upon your consent, you may withdraw this
              consent at any time.
            </p>
          </div>
          <p>
            We recommend you routinely review the privacy notices and preference settings that are
            available to you on any social media platforms as well as your preferences within your
            account with us.
          </p>

          <h2 id="sharing-settings">
            3. <u>Sharing Settings on the Platform.</u>
          </h2>

          <p>
            The Platform allows users to connect with one another. You can configure your settings
            to decide what information you would like to have shared. Please make sure that your
            sharing / visibility settings within your account are configured according to your
            preferences so that your information is only shared with the people with whom you would
            like it shared.
          </p>

          <h2 id="sharing-the-personal">
            4. <u>Sharing the Personal Data We Collect.</u>
          </h2>

          <p>We share your information, including Personal Data, as follows:</p>

          <p>
            4.1. <u>Service Providers and Subcontractors</u>. We disclose information, including
            Personal Data we collect from and/or about you, to our trusted service providers and
            subcontractors, who have agreed to confidentiality restrictions and who use such
            information solely on our behalf in order to: (1) help us provide you with the Platform
            and/or Services; (2) aid in their understanding of how users are using our Platform
            and/or Services; (3) for the purpose of direct marketing (see above for more details).
          </p>

          <p>
            Such service providers and subcontractors provide us with IT and system administration
            services, data backup, security, and storage services, data analysis and assist us with
            our marketing activities.
          </p>

          <p>
            4.2. <u>Business Transfers</u>. Your Personal Data may be disclosed as part of or during
            negotiations of, any merger, sale of company assets or acquisition (including in cases
            of liquidation) in such case, your Personal Data shall continue being subject to the
            provisions of this Privacy Notice.
          </p>

          <p>
            4.3. <u>Law Enforcement Related Disclosure.</u> We may share your Personal Data with
            third parties (i) if we believe in good faith that disclosure is appropriate to protect
            our or a third party's rights, property or safety (including the enforcement of the
            Terms and this Privacy Notice); (ii) when required by law, regulation subpoena, court
            order or other law enforcement related issues, agencies and/or authorities; or (iii) as
            necessary to comply with any legal and/or regulatory obligation.
          </p>

          <p>
            4.4. <u>Legal Uses</u>. We may use your Personal Data as required or permitted by any
            applicable law, for example, to comply with audit and other legal requirements.
          </p>

          <p>
            4.5. <u>Other Uses or Transfer of Your Personal Data</u>. If you use our Platform and/or
            Services with or through a third-party service, site and/or mobile application, we may
            receive information (including Personal Data) about you from those third parties. Please
            note that when you use third-party services outside of our Platform and/or Services,
            their own terms and privacy policies will govern your use of those services.
          </p>

          <h2 id="international-transfer">
            5. <u>International Transfer</u>.
          </h2>

          <p>
            5.1. We use subcontractors and service providers who are located in countries other than
            your own and send them information we receive (including Personal Data). We conduct such
            international transfers for the purposes described above. We will ensure that these
            third parties will be subject to written agreements ensuring the same level of privacy
            and data protection as set forth in this Privacy Notice, including appropriate remedies
            in the event of the violation of your data protection rights in such third country.
          </p>

          <p>
            5.2. Whenever we transfer your Personal Data to third parties based outside of the
            European Economic Area ("<strong>EEA</strong>"), we ensure a similar degree of
            protection is afforded to it by ensuring at least one of the following safeguards is
            implemented:
          </p>

          <div className="sub-list">
            <p>
              5.2.1. We will only transfer your Personal Data to countries that have been deemed to
              provide an adequate level of protection for Personal Data by the European Commission.
            </p>

            <p>
              5.2.2. Where we use certain service providers not located in countries with an
              adequate level of protection as determined by the European Commission, we may use
              specific contracts approved by the European Commission which give Personal Data the
              same protection it has in the EEA.
            </p>
          </div>

          <p>
            5.3. Please contact us at <a href="info@brightsource.com">info@brightsource.com</a> if
            you would like further information on the specific mechanism used by us when
            transferring your Personal Data out of the EEA.
          </p>

          <h2 id="security">
            6. <u>Security</u>.
          </h2>

          <p>
            We have implemented and maintain appropriate technical and organization security
            measures, policies and procedures designed to reduce the risk of accidental destruction
            or loss, or the unauthorized disclosure or access to Personal Data appropriate to the
            nature of such data. The measures we take include:
          </p>

          <p>
            6.1. <u>Safeguards</u> – The physical, electronic, and procedural safeguards we employ
            to protect your Personal Data include secure servers, firewalls, antivirus, and SSL
            encryption of data.
          </p>

          <p>
            6.2. <u>Access Control</u> – We dedicate efforts for a proper management of system
            entries and limit access only to authorized personnel on a need to know basis of least
            privilege rules, review permissions quarterly, and revoke access immediately after
            employee termination.
          </p>

          <p>
            6.3. <u>Internal Policies</u> – We maintain and regularly review and update our privacy
            related and information security policies.
          </p>

          <p>
            6.4. <u>Personnel</u> – We require new employees to sign non-disclosure agreements
            according to applicable law and industry customary practice.
          </p>
          <p>
            6.5. <u>Encryption</u> – We encrypt the data in transit using secure HTTPS protocols.
          </p>

          <p>
            6.6. <u>Database Backup</u> – Our databases are backed up on a periodic basis for
            certain data and are verified regularly. Backups are encrypted and stored within the
            production environment to preserve their confidentiality and integrity, are tested
            regularly to ensure availability, and are accessible only by authorized personnel.
          </p>

          <p>
            However, no method of transmission over the Internet or method of electronic storage is
            100% secure. Therefore, while we strive to use commercially acceptable means to protect
            your Personal Data, we cannot guarantee its absolute security.
          </p>

          <p>
            As the security of information depends in part on the security of the computer you use
            to communicate with us and the security you use to protect user IDs and passwords,
            please take appropriate measures to protect this information.
          </p>

          <h2 id="your-rights">
            7. <u>Your Rights - How to Access and Limit Our Use of Certain Personal Data</u>.
          </h2>

          <p>
            Subject to applicable law and certain exemptions, and in some cases dependent upon the
            processing activity we are undertaking, you have certain rights in relation to the
            Personal Data that we hold about you, as detailed below. We will investigate and attempt
            to resolve complaints and disputes and make every reasonable effort to honor your wish
            to exercise your rights as quickly as possible and, in any event, within the timescales
            provided by applicable data protection laws. We reserve the right to ask for reasonable
            evidence to verify your identity before we comply with any of your requests, as detailed
            below:
          </p>

          <p>
            7.1. <u>Right of Access</u>. You have a right to know what Personal Data we collect
            about you and, in some cases, to have such Personal Data communicated to you. Subject to
            applicable law, we may charge you with a fee. Please note that we may not be able to
            provide you with all the information you request, and, in such case, we will endeavor to
            explain to you why.
          </p>

          <p>
            7.2. <u>Right to Data Portability</u>. If the processing is based on your consent or
            performance of a contract with you and processing is being carried out by automated
            means, you may be entitled to (request that we) provide you or another party with a copy
            of the Personal Data you provided to us in a structured, commonly-used, and
            machine-readable format.
          </p>

          <p>
            7.3. <u>Right to Correct Personal Data</u>. Subject to the limitations in applicable
            law, you may request that we update, complete, correct or delete inaccurate, incomplete,
            or outdated Personal Data.
          </p>

          <p>
            7.4. <u>Deletion of Personal Data ("Right to Be Forgotten")</u>. If you are an EU
            Individual, you have a right to request that we delete your Personal Data if either: (i)
            it is no longer needed for the purpose for which it was collected, (ii) our processing
            was based on your consent and you have withdrawn your consent, (iii) you have
            successfully exercised your Right to Object (see below), (iv) processing was unlawful,
            or (iv) we are required to erase it for compliance with a legal obligation. If you are a
            Job-Seeker, please note that following our deletion of your Personal Data, some Personal
            Data may continue to be retained by Agencies or Companies with which you have shared
            such data including in their Brightsource account/s. We cannot restore information once
            it has been deleted. Please note that to ensure that we do not collect any further
            Personal Data, you should also delete our mobile application from your mobile devices,
            terminate your account with us and clear our cookies from any device where you have
            accessed our Platform. We may retain certain Personal Data following your request to
            delete for audit and record-keeping purposes, or as otherwise permitted and/or required
            under applicable law.
          </p>

          <p>
            7.5. <u>Right to Restrict Processing</u>. If you are an EU Individual, you can ask us to
            limit the processing of your Personal Data if either: (i) you have contested its
            accuracy and wish us to limit processing until this is verified; (ii) the processing is
            unlawful, but you do not wish us to erase the Personal Data; (iii) it is no longer
            needed for the purposes for which it was collected, but we still need it to establish,
            exercise, or defend of a legal claim; (iv) you have exercised your Right to Object
            (below) and we are in the process of verifying our legitimate grounds for processing. We
            may continue to use your Personal Data after a restriction request under certain
            circumstances.
          </p>

          <p>
            7.6. <u>Direct Marketing Opt-Out</u>. You can change your mind at any time about your
            election to receive marketing communications from us and/or having your Personal Data
            processed for direct marketing purposes. If you do, please notify us by contacting us at{' '}
            <a href="info@brightsource.com">info@brightsource.com</a>. We will process your request
            as soon as reasonably possible, however it may take a few days for us to update our
            records before any opt-out is effective.
          </p>

          <p>
            7.7. <u>Right to Object</u>. If you are an EU Individual, you can object to any
            processing of your Personal Data which has our legitimate interests as its legal basis,
            if you believe your fundamental rights and freedoms outweigh our legitimate interests.
            If you raise an objection, we have an opportunity to demonstrate that we have compelling
            legitimate interests which override your rights and freedoms.
          </p>

          <p>
            7.8. <u>Withdrawal of Consent</u>. You may withdraw your consent in connection with any
            processing of your Personal Data based on a previously granted consent. This will not
            affect the lawfulness of any processing prior to such withdrawal.
          </p>

          <p>
            7.9 <u>Right to Lodge a Complaint with Your Local Supervisory Authority</u>. If you are
            an EU Individual, you may have the right to submit a complaint to the relevant
            supervisory data protection authority if you have any concerns about how we are
            processing your Personal Data, though we ask that as a courtesy you please attempt to
            resolve any issues with us first.
          </p>

          <h2 id="data-retention">
            8. <u>Data Retention</u>.
          </h2>

          <p>
            8.1. Subject to applicable law, we retain Personal Data as necessary for the purposes
            set forth above. We may delete information from our systems without notice to you once
            we deem it is no longer necessary for these purposes. If you have shared your Personal
            Data with others on the Platform, they may retain such Personal Data at their own
            discretion, including in their Brightsource account. Retention by any of our processors
            may vary in accordance with the processor's retention policy.
          </p>

          <p>
            8.2. In some circumstances, we may store your Personal Data for longer periods of time,
            for instance where we are required to do so in accordance with legal, regulatory, tax,
            audit, accounting requirements and so that we have an accurate record of your dealings
            with us in the event of any complaints or challenges, or if we reasonably believe there
            is a prospect of litigation relating to your Personal Data or dealings. To determine the
            appropriate retention period, we consider the amount, nature, and sensitivity of the
            Personal Data, the potential risk of harm from unauthorized use or disclosure of your
            Personal Data, the purposes for which we process your Personal Data, and whether those
            purposes can be achieved through other means, as well as applicable legal requirements.
          </p>

          <p>
            8.3. Please contact us at <a href="info@brightsource.com">info@brightsource.com</a> if
            you would like details regarding the retention periods for different types of your
            Personal Data.
          </p>

          <h2 id="cookies-and-similar-technologies">
            9. <u>Cookies and Similar Technologies</u>.
          </h2>

          <p>
            We use cookies and similar technologies for a number of reasons, including to help
            personalize your experience and to personalize the ads we serve you. Third parties
            through which we provide the Services may be placing and reading cookies on your browser
            or using web beacons to collect information in the course of advertising being served on
            different websites. When visiting this Platform, you shall be notified of the use of and
            placement of cookies and other similar technologies on your device as specified herein.
          </p>

          <p>
            9.1. <u>What are Cookies?</u> A cookie is a small piece of text that is sent to a user's
            browser or device. The browser provides this piece of text to the device of the
            originating user when this user returns.
          </p>

          <div className="sub-list">
            <p>
              9.1.1. A "session cookie" is temporary and will remain on your device until you leave
              the Platform.
            </p>

            <p>
              9.1.2. A "persistent" cookie may be used to help save your settings and customizations
              across visits. It will remain on your device until you delete it.
            </p>

            <p>
              9.1.3. First-party cookies are placed by us, while third-party cookies may be placed
              by a third party. We use both first- and third-party cookies.
            </p>

            <p>
              9.1.4. We may use the terms "cookies" to refer to all technologies that we may use to
              store data in your browser or device or that collect information or help us identify
              you in the manner described above, such as web beacons or "pixel tags".
            </p>
          </div>

          <p>
            9.2. <u>How We Use Cookies</u>. We use cookies and similar technologies for a number of
            reasons. The specific names and types of the cookies, web beacons, and other similar
            technologies we use may change from time to time. However, the cookies we use generally
            fall into one of the following categories:
          </p>

          <table>
            <thead>
              <tr>
                <th>Type of cookie</th>
                <th>Why We Use These Cookies</th>
              </tr>
            </thead>

            <tbody>
              {dataTable.map((row) => (
                <tr key={row.id}>
                  <td>{row.column1}</td>
                  <td>{row.column2}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <p>
            9.3. <u>How to Adjust Your Preferences</u>. Most Web browsers are initially configured
            to accept cookies, but you can change this setting so your browser either refuses all
            cookies or informs you when a cookie is being sent. In addition, you are free to delete
            any existing cookies at any time. Please note that some features of the Services may not
            function properly when cookies are disabled or removed. For example, if you delete
            cookies that store your account information or preferences, you will be required to
            input these each time you visit.
          </p>

          <p>
            By changing your device settings, you can prevent your device's ad identifier being used
            for interest-based advertising, or you can reset your device's ad identifier. Typically,
            you can find the ad identifier settings under "privacy" or "ads" in your device's
            settings, although settings may vary from device to device. Adjusting your preferences
            as described in this section herein does not mean you will no longer receive
            advertisements, it only means the advertisements that you do see will be less relevant
            to your interests.
          </p>

          <h2 id="third-party-applications-and-services">
            10. <u>Third-Party Applications and Services</u>.
          </h2>

          <p>
            All use of third-party applications or services is at your own risk and subject to such
            third party's terms and privacy policies.
          </p>

          <h2 id="communications">
            11. <u>Communications</u>.
          </h2>

          <p>
            We reserve the right to send you service-related communications, including service
            announcements and administrative messages, without offering you the opportunity to opt
            out of receiving them. Should you not wish to receive such communications, you may
            cancel your account.
          </p>

          <h2 id="children">
            12. <u>Children</u>.
          </h2>

          <p>
            We do not knowingly collect Personal Data from children under the age of sixteen (16).
            In the event that you become aware that an individual under the age of sixteen (16) has
            enrolled without parental permission, please advise us immediately.
          </p>

          <h2 id="changes-to-the-privacy-notice">
            13. <u>Changes to the Privacy Notice</u>.
          </h2>

          <p>
            We may update this Privacy Notice from time to time to keep it up to date with legal
            requirements and the way we operate our business, and we will place any updates on this
            webpage. Please come back to this page every now and then to make sure you are familiar
            with the latest version. If we make material changes to this Privacy Notice, we will
            seek to inform you by notice on our Platform or per email.
          </p>

          <h2 id="comments-and-questions">
            14. <u>Comments and Questions</u>.
          </h2>

          <p>
            If you have any comments or questions about this Privacy Notice or if you wish to
            exercise any of your legal rights as set out herein, please contact us at{' '}
            <a href="info@brightsource.com">info@brightsource.com</a>.
          </p>

          <i>Last updated: January 2021</i>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

const sections = [
  { id: 1, hash: '#personal-data', text: 'Personal Data We Collect, Uses and Legal Basis' },
  { id: 2, hash: '#additional-uses', text: 'Additional Uses' },
  { id: 3, hash: '#sharing-settings', text: 'Sharing Settings on the Platform' },
  { id: 4, hash: '#sharing-the-personal', text: 'Sharing the Personal Data We Collect' },
  { id: 5, hash: '#international-transfer', text: 'International Transfer' },
  { id: 6, hash: '#security', text: 'Security' },
  {
    id: 7,
    hash: '#your-rights',
    text: 'Your Rights - How to Access and Limit Our Use of Certain Personal Data',
  },
  { id: 8, hash: '#data-retention', text: 'Data Retention' },
  { id: 9, hash: '#cookies-and-similar-technologies', text: 'Cookies and Similar Technologies' },
  {
    id: 10,
    hash: '#third-party-applications-and-services',
    text: 'Third-Party Applications and Services',
  },
  { id: 11, hash: '#communications', text: 'Communications' },
  { id: 12, hash: '#children', text: 'Children' },
  { id: 13, hash: '#changes-to-the-privacy-notice', text: 'Changes to the Privacy Notice' },
  { id: 14, hash: '#comments-and-questions', text: 'Comments and Questions' },
];

const dataTable = [
  {
    id: 1,
    column1: 'Necessary',
    column2:
      'These cookies are necessary in order to allow the Platform to work correctly. They enable you to access the Platform, move around, and access different services, features, and tools. Examples include remembering previous actions (e.g. entered text) when navigating back to a page in the same session. These cookies cannot be disabled.',
  },
  {
    id: 2,
    column1: 'Functionality',
    column2:
      'These cookies remember your settings and preferences and the choices you make (such as language or regional preferences) in order to help us personalize your experience and offer you enhanced functionality and content.',
  },
  {
    id: 3,
    column1: 'Security',
    column2:
      'These cookies can help us identify and prevent security risks. They may be used to store your session information to prevent others from changing your password without your login information.',
  },
  {
    id: 4,
    column1: 'Performance',
    column2:
      'These cookies can help us collect information to help us understand how you use our Platform, for example whether you have viewed messages or specific pages and how long you spent on each page. This helps us improve the performance of our Platform.',
  },
  {
    id: 5,
    column1: 'Analytics',
    column2:
      'These cookies collect information regarding your activity on our Platform to help us learn more about which features are popular with our users and how our Platform can be improved.',
  },
  {
    id: 6,
    column1: 'Advertising',
    column2:
      'These cookies are placed in order to deliver content, including ads relevant and meaningful to you and your interests. They may also be used to deliver targeted advertising or to limit the number of times you see an advertisement. This can help us track how efficient advertising campaigns are. Such cookies may track your browsing habits and activity when visiting our Platform and those of third-parties.',
  },
];
