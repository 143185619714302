import { AnchorButton, Tab, Tabs } from '@blueprintjs/core';
import { Logo } from 'assets/svg';
import { VARS } from 'config';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ROUTE_LIST } from './routes';

function HeaderDesktop() {
  const { pathname } = useLocation();

  return (
    <header className="header-desktop">
      <div className="header-container">
        <Link to="/" className="header-logo">
          <Logo />
        </Link>

        <Tabs id="header-tabs" selectedTabId={pathname}>
          {ROUTE_LIST.map((route) => {
            return (
              <Tab
                key={route.path}
                id={route.path}
                title={
                  route.href ? (
                    <a href={route.href}>{route.label}</a>
                  ) : (
                    <NavLink to={route.path}>{route.label}</NavLink>
                  )
                }
              />
            );
          })}
        </Tabs>

        <div className="header-actions">
          {/* <Button intent="primary" >
            Post a Job
          </Button> */}

          <AnchorButton intent="primary" href={`${VARS.talentUrl}/login`}>
            Log in
          </AnchorButton>
        </div>
      </div>
    </header>
  );
}

export default HeaderDesktop;
