import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

import './header.scss';

export function Header() {
  return (
    <>
      <HeaderDesktop />
      <HeaderMobile />
    </>
  );
}
