export const Leaf1 = ({ contained = false }) => (
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill={contained ? '#FFAE00' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2921 2.1415L10.3 2.14148L10.3079 2.14138L49.7527 1.6364L49.7555 1.63636C76.6874 1.24549 98.7409 23.1005 98.3823 50.1804C98.0402 75.1125 77.5378 96.2317 52.5939 97.3224C25.5968 98.4951 3.1343 77.2757 2.54747 50.5039L2.54726 50.5039L1.95797 10.622L1.95795 10.6213C1.88735 5.98057 5.63047 2.15561 10.2921 2.1415Z"
      stroke="#FFAE00"
      strokeWidth="3.2243"
    />
    <path
      d="M50.6675 26.6295L37.2478 40.3514L40.5725 43.8575L48.5517 35.4551V68.9438H54.234V26.6295H50.6675Z"
      fill={contained ? '#FFF' : '#FFAE00'}
    />
  </svg>
);

export const Leaf2 = ({ contained = false }) => (
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill={contained ? '#00C27F' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2921 2.1415L10.3 2.14148L10.3079 2.14138L49.7527 1.6364L49.7555 1.63636C76.6874 1.24549 98.7409 23.1005 98.3823 50.1804C98.0402 75.1125 77.5378 96.2317 52.5939 97.3224C25.5968 98.4951 3.1343 77.2757 2.54747 50.5039L2.54726 50.5039L1.95797 10.622L1.95795 10.6213C1.88735 5.98057 5.63047 2.15561 10.2921 2.1415Z"
      stroke="#00C27F"
      strokeWidth="3.2243"
    />
    <path
      d="M33.6583 39.8683L39.2196 40.7146C40.0659 33.7025 44.116 31.0428 48.9519 31.0428C54.3318 31.0428 57.2938 34.0048 57.2938 38.2967C57.2938 42.8303 54.5132 46.3364 45.9294 55.1015L33.8396 68.0376V68.9443H63.641V64.1084H44.2369L51.793 56.7336C59.6514 48.573 63.097 43.7371 63.097 38.3571C63.097 30.801 57.717 26.0255 49.0123 26.0255C41.5771 26.0255 35.1695 29.592 33.6583 39.8683Z"
      fill={contained ? '#FFF' : '#00C27F'}
    />
  </svg>
);

export const Leaf3 = ({ contained = false }) => (
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill={contained ? '#00C8DF' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2921 2.12246L10.3 2.12243L10.3079 2.12233L49.7527 1.61735L49.7555 1.61731C76.6874 1.22644 98.7409 23.0814 98.3823 50.1614C98.0402 75.0934 77.5378 96.2126 52.5939 97.3034C25.5968 98.476 3.1343 77.2567 2.54747 50.4848L2.54726 50.4848L1.95797 10.6029L1.95795 10.6022C1.88735 5.96153 5.63047 2.13657 10.2921 2.12246Z"
      stroke="#00C8DF"
      strokeWidth="3.2243"
    />
    <path
      d="M54.923 46.196C58.3081 44.7452 60.7865 41.7227 60.7865 37.1286C60.7865 30.6606 55.7693 26.006 47.0646 26.006C40.4757 26.006 35.5793 28.9075 32.9195 33.139L36.4256 36.101C39.2667 32.2927 42.8332 30.8419 46.6415 30.8419C52.0214 30.8419 55.1043 33.3203 55.1043 37.5517C55.1043 41.6623 51.7192 44.1407 46.7624 44.1407H41.3219V48.7953H47.4273C53.17 48.7953 57.5827 51.0923 57.5827 56.3514C57.5827 61.6105 53.5326 64.6934 47.1855 64.6934C42.1078 64.6934 38.1181 62.2754 35.7002 59.1321L31.7105 62.0941C33.8867 65.721 39.2062 69.5293 47.4273 69.5293C57.4014 69.5293 63.2649 64.3307 63.2649 56.8954C63.2649 50.8505 59.4567 47.1631 54.923 46.196Z"
      fill={contained ? '#FFF' : '#00C8DF'}
    />
  </svg>
);

export const Leaf4 = ({ contained = false }) => (
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill={contained ? '#FF5527' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2921 2.1415L10.3 2.14148L10.3079 2.14138L49.7527 1.6364L49.7555 1.63636C76.6874 1.24549 98.7409 23.1005 98.3823 50.1804C98.0402 75.1125 77.5378 96.2317 52.5939 97.3224C25.5968 98.4951 3.1343 77.2757 2.54747 50.5039L2.54726 50.5039L1.95797 10.622L1.95795 10.6213C1.88735 5.98057 5.63047 2.15561 10.2921 2.1415Z"
      stroke="#FF5527"
      strokeWidth="3.2243"
    />
    <path
      d="M63.7854 56.0682H58.4659V26.6295H53.8113L31.566 56.3704V60.9041H52.7837V68.9438H58.4659V60.9041H63.7854V56.0682ZM52.9045 35.5155V56.0682H37.6109L52.9045 35.5155Z"
      fill={contained ? '#FFF' : '#FF5527'}
    />
  </svg>
);

export const Leaf5 = ({ contained = false }) => (
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill={contained ? '#FFAE00' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2921 2.1415L10.3 2.14148L10.3079 2.14138L49.7527 1.6364L49.7555 1.63636C76.6874 1.24549 98.7409 23.1005 98.3823 50.1804C98.0402 75.1125 77.5378 96.2317 52.5939 97.3224C25.5968 98.4951 3.1343 77.2757 2.54747 50.5039L2.54726 50.5039L1.95797 10.622L1.95795 10.6213C1.88735 5.98057 5.63047 2.15561 10.2921 2.1415Z"
      stroke="#FFAE00"
      strokeWidth="3.2243"
    />
    <path
      d="M50.141 41.3191C48.6297 41.3191 46.8767 41.44 45.0028 41.7423L46.0304 31.4659H63.4398V26.63H40.7109L38.9579 46.6991L39.1997 46.7595C42.4639 46.0341 45.2446 45.6715 47.7835 45.6715C55.3396 45.6715 58.9665 49.782 58.9665 55.2829C58.9665 61.0255 54.6142 64.5316 48.932 64.5316C45.1237 64.5316 42.2826 63.7457 39.4415 62.2345L37.6885 66.9495C41.0132 68.6421 44.3983 69.5488 49.5365 69.5488C58.362 69.5488 64.8905 63.6853 64.8905 55.5246C64.8905 47.7872 60.4778 41.3191 50.141 41.3191Z"
      fill={contained ? '#FFF' : '#FFAE00'}
    />
  </svg>
);
