import ClaimSection from 'components/claim-section';
import CompaniesSection from 'components/companies-section';
import HeroSection from 'components/hero-section';
import JobsSection from 'components/jobs-section';
import RecruitersSection from 'components/recruiters-section';
import EmployerFeatured from './EmployerFeatured';

import './employer.scss';

function EmployerPage() {
  return (
    <div className="employer-page">
      <HeroSection
        isEmployer
        title="Dream big.<br />Hire big."
        subtitle=""
        description="Brightsource helps startups reach the right talent, respond faster, and create an excellent candidate experience every time."
      />

      <CompaniesSection />
      <EmployerFeatured />
      <RecruitersSection />
      <JobsSection />
      <ClaimSection />
    </div>
  );
}

export default EmployerPage;
