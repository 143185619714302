import { Button, Collapse } from '@blueprintjs/core';
import cn from 'classnames';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './tableOfContents.scss';

export function TableOfContents({ children, sections = [] }) {
  const { hash } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="table-of-contents">
      <Button
        fill
        minimal
        rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
        alignText="left"
        onClick={() => setIsOpen((x) => !x)}
      >
        On this page
      </Button>

      <nav className={cn({ isOpen })}>
        <Collapse keepChildrenMounted isOpen={isOpen}>
          {children}

          <ol>
            {sections.map((section) => (
              <li key={section.id}>
                <a href={section.hash} className={cn({ active: section.hash === hash })}>
                  {section.text}
                </a>
              </li>
            ))}
          </ol>
        </Collapse>
      </nav>
    </div>
  );
}
