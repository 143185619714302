import { Leaf1 } from 'assets/svg';
import './leafStep.scss';

export function LeafStep(props) {
  const { heading, description, icon: Icon = Leaf1 } = props;

  return (
    <div className="leaf-step">
      <div className="leaf-step-icon">
        <Icon />
        <Icon contained />
      </div>

      <div className="leaf-step-content">
        <h6>{heading}</h6>
        <p>{description}</p>
      </div>
    </div>
  );
}
