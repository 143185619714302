import { VARS } from 'config';
import { useEffect, useState } from 'react';

export function useSearchJob(term) {
  const [jobs, setJobs] = useState([]);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (!term) {
      setJobs([]);
      return;
    }

    (async () => {
      setSearching(true);

      try {
        const response = await fetch(`${VARS.apiUrl}/api/public/jobs/suggest?term=${term}`);
        const { data } = await response.json();

        setJobs(data?.keys || []);
      } catch (error) {
        console.error('Failed to search job', error);
      }

      setSearching(false);
    })();
  }, [term]);

  return {
    jobs,
    searching,
  };
}
