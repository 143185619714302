import { FeaturedShape1 } from 'assets/images';
import './notFound.scss';

function NotFound() {
  return (
    <div className="not-found-page">
      <h1>404</h1>
      <p>The page you are looking for does not exist</p>

      <div className="not-found-page-bottom">
        <img src={FeaturedShape1} alt="" />
      </div>
    </div>
  );
}

export default NotFound;
