import { Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, FeaturedShape } from 'assets/images';
import Slider from 'react-slick';

import './recruitersSection.scss';

function RecruitersSection() {
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 2,
    variableWidth: true,
    autoplay: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
        },
      },
    ],
  };

  return (
    <section className="recruiters-section">
      <div className="container">
        <h6>
          Independent professionals recruiters <span>everywhere</span>
        </h6>

        <div className="recruiters-section-main">
          <img src={Avatar1} alt="" />
          <img src={Avatar2} alt="" />
          <img src={Avatar3} alt="" />
          <img src={Avatar4} alt="" />
          <img src={Avatar5} alt="" />
        </div>

        <Slider {...settings}>
          <div style={{ width: '120px' }}>
            <img src={Avatar1} alt="" />
          </div>
          <div style={{ width: '120px' }}>
            <img src={Avatar2} alt="" />
          </div>
          <div style={{ width: '120px' }}>
            <img src={Avatar3} alt="" />
          </div>
          <div style={{ width: '120px' }}>
            <img src={Avatar4} alt="" />
          </div>
          <div style={{ width: '120px' }}>
            <img src={Avatar5} alt="" />
          </div>
        </Slider>
      </div>

      <div className="recruiters-section-bottom">
        <img src={FeaturedShape} alt="" />
      </div>
    </section>
  );
}

export default RecruitersSection;
