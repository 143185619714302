import cn from 'classnames';
import { TableOfContents } from 'components/common/table-of-contents';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './termsOfUse.scss';

function TermsOfUse() {
  const { hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tou">
      <div className="tou-inner container">
        <TableOfContents sections={sections}>
          <a href="#terms-of-service" className={cn({ active: hash === '#terms-of-service' })}>
            <strong>Terms of service</strong>
          </a>
        </TableOfContents>

        <div className="tou-content">
          <h1 id="terms-of-service">Terms of Service</h1>

          <p>
            BrightSource Data Analytics Ltd. ("<strong>BrightSource</strong>", "<strong>us</strong>
            ", "<strong>our</strong>", or "<strong>we</strong>"), a company incorporated in the
            State of Israel with Company No. 515417806 and registered offices at Pinchas Rosen 72,
            Tel Aviv, Israel, has developed a platform that allows job-seekers, recruiters,
            employment agencies, and companies seeking employees to receive information and connect
            ("<strong>Platform</strong>") available to you through this website and our mobile
            application. These Terms of Service ("<strong>Terms</strong>") govern your access to and
            use of the Platform and any services available thereon ("<strong>Services</strong>").
            Our Privacy Notice, available at{' '}
            <Link to="/privacy-policy">https://brightsource.com/privacy-policy</Link> ("
            <strong>Privacy Notice</strong>") governs our collection, processing and transfer of any
            Personal Data (as defined in the Privacy Notice). "<strong>You</strong>" means an
            individual using the Services either to find an available position ("
            <strong>Job Seeker</strong>"), on behalf of a recruiter or an employment agency ("
            <strong>Agency</strong>") or entity or company seeking to fill open positions ("
            <strong>Company</strong>").
          </p>

          <p>
            Please read these Terms carefully. By clicking on the button marked "I agree" you
            signify your assent to these Terms. Changes may be made to these Terms from time to
            time. Any change will become effective on the date the updated Terms are posted on the
            Platform. If you do not agree to any of these Terms, please do not click the button
            marked "I agree" and do not use the Platform or Services.
          </p>

          <p>
            If you are registering on behalf of a Company or an Agency, you represent that you are
            authorized to enter into, and bind such entity to these Terms and to register for the
            Services on its behalf. You are solely responsible for ensuring that these Terms are in
            compliance with all laws, rules, and regulations applicable to you and the Company or
            Agency, as applicable, and the right to access the Services is revoked where these Terms
            or use of the Services is prohibited.
          </p>

          <p>
            In the event of a conflict between these Terms and a license agreement we have executed
            with the applicable Company or Agency ("<strong>License Agreement</strong>") separately
            prior to entering into these Terms, the provisions of such License Agreement, if and as
            applicable, shall prevail
          </p>

          <h2 id="use-of-services">1. Use of Services</h2>

          <p>
            1.1. Subject to these Terms and the License Agreement, BrightSource allows you to access
            and use the Platform and Services on a non-exclusive basis. BrightSource may, at its
            sole discretion and at any time, modify or discontinue providing the Platform or
            Services or any part thereof without notice and shall not be liable to you or any third
            party for any modification or discontinuance of the Services.
          </p>

          <p>
            1.2. Use of and access to the Services is void where prohibited by law. You represent
            and warrant that (a) any and all registration information you submit is truthful and
            accurate; (b) you will maintain the accuracy of such information; (c) you are 18 years
            of age or older (if you are an individual) and have the ability to form a binding
            contract; (d) your use of the Services shall be for professional purposes only and does
            not violate any applicable law, regulation, or obligation you may have to a third party;
            and (e) you shall comply with applicable laws, regulations, guidelines, and these Terms
            throughout your use of the Platform and/or Services.
          </p>

          <p>
            1.3. You acknowledge and agree that from time to time, we may use aggregate and
            anonymous information for analytical purposes, for the purpose of improving the Platform
            and/or Services as well as for other internal purposes ("Aggregate Data"). This
            Aggregate Data may contain information derived from information provided by a Company,
            provided that the Aggregate Data will not be derived solely from one specific Company or
            Agency, and cannot identify or be associated with any particular Company, Agency,
            Job-Seeker, or any other individual.
          </p>

          <h2 id="account-registration">2. Account Registration</h2>

          <p>
            2.1. In order to use the Platform and/or Services, you will need to have a registered
            account. Depending on the designation of your account, as either a Job Seeker, an
            Agency, or a Company, different services may be available to you. You can register by
            logging in through your Google, LinkedIn, Facebook, or other third-party login account
            ("Login Account") as may be permitted by BrightSource from time to time. By registering
            through a Login Account, you represent and warrant that such Login Account is yours and
            that you have full rights to provide us with the information in such Login Account.
            Depending on the designation of your account type, the registration process may require
            different information. To complete the registration process, you must provide any
            (additional) registration information requested by us. We may indicate that the
            provision of some information is optional, but your agreement to provide such
            information may assist us in providing you with improved Services.
          </p>

          <p>
            2.2. Users accessing the Platform on behalf of a Company or Agency may be designated as
            an "Administrator" or as an "Authorized User". An Administrator may register additional
            Authorized User accounts for users of the Company or Agency and may provide the names
            and email addresses of additional Authorized Users. Each additional authorized user will
            be required to complete the registration process and accept these Terms. If you are an
            Authorized User, certain information about your use of the Platform may be available to
            the applicable Administrator. If you are an Administrator, you represent and warrant
            that you have provided all necessary notices and received all necessary consents as
            required under applicable law and you have the full right and power to provide
            BrightSource with any Personal Data of any third party and to allow BrightSource to
            process and share such Personal Data for the purpose of provision of the Services and
            for its own internal business purposes, all as detailed in the Privacy Notice, including
            allowing such Personal Data to be displayed publicly on the Platform.
          </p>

          <p>
            2.3. BrightSource may refuse to open an account for any individual or entity at its sole
            discretion.
          </p>

          <p>
            2.4. You agree to notify us immediately of any unauthorized use of your account or
            password. You are fully and solely responsible for the security of your computer system
            and/or mobile device and all activity on your account, even if such activities were not
            committed by you. To the fullest extent permitted by applicable law, BrightSource will
            not be liable for any losses or damages arising from unauthorized use of your account or
            password, and you agree to indemnify and hold BrightSource harmless for any
            unauthorized, improper or illegal use of your account and any charges and taxes
            incurred, unless you have notified us via e-mail at{' '}
            <a href="mailto:info@brightsource.com">info@brightsource.com</a> that your account has
            been compromised and have requested that we block access to it, which we will do as soon
            as reasonably practicable. We do not police for and cannot guarantee that we will learn
            of or prevent, any inappropriate use of the Services.
          </p>

          <h2 id="suspension-and-termination-of-account">
            3. Suspension and Termination of Account
          </h2>

          <p>
            3.1. You agree that BrightSource may, without notice, suspend or terminate your account,
            and may remove any Content (as defined below) associated with your account and take any
            other corrective action it deems appropriate due to (i) violation of the letter or
            spirit of these Terms, (ii) fraudulent, harassing or abusive behavior, (iii) behavior
            that is illegal or harmful to other users, third parties, or the business interests of
            BrightSource, (iv) termination of the License Agreement for any reason, or (v) failure
            of the Company or Agency, as applicable, to make payment under the License Agreement. In
            addition, if you are a Job-Seeker who uses the free version of the Platform,
            BrightSource may suspend or terminate your account for any reason at its discretion. If
            your account is terminated, you may not rejoin BrightSource again without express
            permission. Upon termination of your account, you shall not have any further access to
            any Content that may be available through your account. If your Job-Seeker account is
            terminated, your Personal Data may be retained by Agencies or Companies with whom you
            have shared your Personal Data, as described more fully in the Privacy Notice.
          </p>

          <p>
            3.2. We reserve the right to investigate suspected violations of these Terms or illegal
            and inappropriate behavior through the Services. We will fully cooperate with any law
            enforcement authorities or court order requesting or directing us to disclose the
            identity, behavior or Content of anyone believed to have violated these Terms or to have
            engaged in illegal behavior in connection with the Services.
          </p>

          <p>
            3.3. You may request termination of your BrightSource account at any time and for any
            reason by sending an email to{' '}
            <a href="mailto:info@brightsource.com">info@brightsource.com</a> or may deactivate your
            account through your account settings. Following such request, BrightSource shall close
            your account as soon as reasonably practicable. An Administrator may also close accounts
            of Authorized Users. Any suspension or termination of your account shall not affect your
            obligations under these Terms (including but not limited to ownership, indemnification,
            any representations and warranties made by you, and limitation of liability), which by
            their sense and context are intended to survive such suspension or termination.
          </p>

          <h2 id="fees-and-payment">4. Fees and Payment</h2>

          <p>
            Payment for the use of the Platform on behalf of a Company or Agency shall be in
            accordance with the terms of the License Agreement.
          </p>

          <h2 id="content">5. Content</h2>

          <p>
            5.1. Certain types of content may be made available through the Services. "Content" as
            used in these Terms means, collectively, all content on or made available through the
            Services, including any images, photos, pictures, videos, information, and any
            modifications or derivatives of the foregoing. BrightSource allows you to post certain
            Content including but not limited to images, pictures, videos, CVs, information
            regarding past experiences, and information regarding open positions, on or through the
            Services, referred to herein as "User Content".
          </p>

          <p>
            5.2. BRIGHTSOURCE DOES NOT ENDORSE ANY CONTENT (INCLUDING WITHOUT LIMITATION ANY USER
            CONTENT) OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND
            EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION THEREWITH. BRIGHTSOURCE
            DISCLAIMS ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF
            ANY AND ALL USERS (INCLUDING UNAUTHORIZED USERS) THAT ARE NOT DUE TO BRIGHTSOURCE'S
            GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING THE
            USE OF THE SERVICE OR OTHERWISE.
          </p>

          <h2 id="user-content-restrictions">6. User Content Restrictions</h2>

          <p>
            6.1. BrightSource has no obligation to accept, display, or maintain any User Content.
            Moreover, BrightSource reserves the right to remove and permanently delete any User
            Content uploaded by you, without notice and for any reason. You are and shall remain at
            all times fully and solely responsible for any User Content that you upload to the
            Services. You represent and warrant that any User Content that you upload (i) complies
            with applicable law; (ii) does not infringe or violate any third-party intellectual
            property rights, privacy or publicity rights, or moral rights; and (iii) that you have
            all necessary rights and authorities to submit such User Content, including, without
            limitation, logos or other intellectual property belonging to the Company, if
            applicable. Any User Content posted or submitted through the Services shall not be
            considered confidential and may be disseminated by BrightSource without compensation to
            you.
          </p>

          <p>
            6.2. Without limiting the foregoing, you agree that you will not transmit, submit or
            upload any User Content or act in any way that: (1) restricts of inhibits use of the
            Services; (2) violates the legal rights of others, including defaming, abuse, stalking
            or threatening users; (3) infringes (or results in the infringement of) the intellectual
            property rights, moral rights, publicity, privacy, or other rights of any third party;
            (4) is (or you reasonably believe or should reasonably believe to be) stolen, illegal,
            counterfeit, fraudulent, pirated, violent or unauthorized, or in furtherance of any
            illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity, or
            that involves (or you reasonably believe or should reasonably believe to involve) any
            stolen, illegal, counterfeit, fraudulent, pirated, or unauthorized material; (5) does
            not comply with all applicable laws, rules and regulations; (6) imposes an unreasonably
            or disproportionately large load on our infrastructure; or (7) posts, stores, transmits,
            offers, or solicits anything that contains the following, or that you know contains
            links to the following or to locations that in turn contain links to the following: (a)
            material that we determine to be offensive (including material promoting or glorifying
            hate, violence, bigotry, or any entity (past or present) principally dedicated to such
            causes or items associated with such an entity), (b) material that is racially or
            ethnically insensitive, material that is defamatory, harassing or threatening, (c)
            pornography or obscene material, (d) any virus, worm, trojan horse, or other harmful or
            disruptive component or (e) anything that encourages conduct that would be considered a
            criminal offense, give rise to civil liability, violate any law or regulation or is
            otherwise inappropriate or offensive.
          </p>

          <p>
            6.3. BrightSource may, at its sole discretion, choose to monitor User Content for
            inappropriate or illegal behavior, including through automatic means, provided however,
            that BrightSource reserves the right to treat User Content as content stored at the
            direction of users for which BrightSource will not exercise editorial control except
            when violations are directly brought to BrightSource attention.
          </p>

          <p>
            6.4. Content comes from a variety of sources. You understand that BrightSource is not
            responsible for the accuracy, usefulness, safety, appropriateness of, or infringement of
            any intellectual property rights of or relating to this Content (including but not
            limited to the User Content). Although users must agree to these Terms, it is possible
            that other users (including unauthorized users) may post or transmit offensive or
            obscene materials and that you may be involuntarily exposed to such offensive or obscene
            materials. You hereby waive any legal or equitable rights or remedies you have or may
            have against us with respect thereto. It is also possible for others to obtain Personal
            Data about you due to your use of the Platform, including through any User Content that
            you make available through your account. Anyone receiving or viewing User Content may
            use information you provided through such User Content (such as your contact details,
            location or description of an entity you represent) for purposes other than what you
            intended. We are not responsible for the use of any Personal Data that you disclose on
            the Platform or through any User Content by any third party. By making any information
            available through the Platform you acknowledge that you understand and have agreed to
            such risks.
          </p>

          <h2 id="use-restrictions">7. Use Restrictions</h2>

          <p>
            You may not do or attempt to do or facilitate a third party in doing any of the
            following: (1) decipher, decompile, disassemble, or reverse-engineer any of the software
            and/or code, if and as applicable, used to provide the Platform or Services without our
            prior written authorization, including framing or mirroring any part of the Platform or
            Services; (2) circumvent, disable, or otherwise interfere with security-related features
            of the Services or features that prevent or restrict use or copying of any Content; (3)
            use the Platform or Services or content thereon in connection with any commercial
            endeavors in any manner, except for the purposes specifically set forth in these Terms;
            (4) use any robot, spider, site search or retrieval application, or any other manual or
            automatic device or process to retrieve, index, data-mine, or in any way reproduce or
            circumvent the navigational structure or presentation of the Platform or Services; (5)
            use or access another user’s account or password without permission; or (6) use the
            Platform or Services or content thereon in any manner not permitted by these Terms.
          </p>

          <h2 id="intellectual-property">8. Intellectual Property</h2>

          <p>
            8.1. BrightSource or its licensors, as the case may be, have all right, title and
            interest in the Platform or Services, and any Content thereon, including its overall
            appearance, text, graphics, graphics design, videos, interfaces, and underlying source
            files, and all worldwide intellectual property rights, the trademarks, service marks,
            and logos contained therein, whether registered or unregistered. Except as expressly
            permitted herein, you may not copy, further develop, reproduce, republish, modify, alter
            download, post, broadcast, transmit or otherwise use the Content of the Platform or
            Services for any purpose. You will not remove, alter or conceal any copyright,
            trademark, service mark or other proprietary rights notices incorporated therein, if
            any. All trademarks are trademarks or registered trademarks of their respective owners.
            Nothing in these Terms or in the Platform or Services should be construed as granting
            you any right to use any trademark, service mark, logo, or trade name of BrightSource or
            any third party. If you provide BrightSource with any feedback regarding the Services,
            BrightSource may use all such feedback without restriction and shall not be subject to
            any non-disclosure or non-use obligations in respect of such feedback.
          </p>

          <p>
            8.2. You have all right, title and interest in the User Content you submit. By
            submitting or posting any User Content, you grant BrightSource and its successors and
            assignees a worldwide, non-exclusive, royalty-free, perpetual, sub-licensable and
            transferable license under any of your intellectual property, moral or privacy rights to
            use, copy, distribute, transmit, modify, prepare derivative works of, publicly display,
            alter, decompile, publicly perform such User Content on, through or in connection with
            the Platform in any media formats and through any media channels, including, without
            limitation, for commercially promoting the Platform or any BrightSource services.
          </p>

          <h2 id="copyright">9. Copyright</h2>

          <p>
            9.1. The policy of BrightSource is not to infringe upon or violate the intellectual
            property rights or other rights of any third party, and BrightSource will refuse to use
            and remove any User Content that infringes the rights of any third party. Under the
            Digital Millennium Copyright Act of 1998 (the "DMCA"), BrightSource will remove any
            Content (including, without limitation, any User Content) if properly notified that such
            material infringes third party rights, and may do so at its sole discretion, without
            prior notice to users at any time. The policy of BrightSource is to terminate the
            accounts of repeat infringers in appropriate circumstances.
          </p>

          <p>
            9.2. You are in the best position to judge whether User Content is in violation of
            intellectual property or personal rights of any third-party. You accept full
            responsibility for avoiding infringement of the intellectual property or personal rights
            of others in connection with User Content.
          </p>

          <p>
            9.3. If you believe that something appearing on the Platform infringes your copyright,
            you may send us a notice requesting that it be removed, or that access to it blocked. If
            you believe that such a notice has been wrongly filed against you, the DMCA allows you
            send us a counter-notice. Notices and counter-notices must meet the DMCA’s requirements.
            We suggest that you consult with your legal advisor before filing a notice or
            counter-notice. Be aware that there can be substantial penalties for false claims. Send
            notices and counter-notices to us at{' '}
            <a href="mailto:info@brightsource.com">info@brightsource.com</a>.
          </p>

          <h2 id="disclaimers-and-disclaimer-of-warranty">
            10. Disclaimers and Disclaimer of Warranty
          </h2>
          <p>
            10.1. All information and Content posted on the Platform is for informational purposes
            only and BrightSource provides no guarantees with respect thereto. Your use of the
            Platform, and/or Services is at your sole discretion and risk. The Platform, and Content
            provided thereon, are provided on an AS IS and AS AVAILABLE basis without warranties of
            any kind. We do not represent or warrant that the Services will be of good quality or
            useful for your needs.
          </p>

          <p>
            10.2. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY,
            RELATING TO THE PLATFORM OR SERVICES OR ANY CONTENT THEREON, INCLUDING WITHOUT
            LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE
            DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY,
            RELIABILITY, TIMELINESS AND PERFORMANCE OF THE PLATFORM OR SERVICES; OR (II) THAT THE
            PLATFORM OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III)
            REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS
            OF ANY INFORMATION PROVIDED ON THE PLATFORM OR SERVICES.
          </p>

          <p>
            10.3. No advice or information, whether oral or written, obtained by you from us, shall
            create any warranty that is not expressly stated in these Terms. If you choose to rely
            on such information, you do so solely at your own risk. Some states or jurisdictions do
            not allow the exclusion of certain warranties. Accordingly, some of the above exclusions
            may not apply to you. Check your local laws for any restrictions or limitations
            regarding the exclusion of implied warranties.
          </p>

          <p>
            10.4. You acknowledge and agree that BrightSource is not a data retention service. You
            therefore must create backups of your data, and BrightSource shall have no
            responsibility or liability in respect of any loss of, damage to, or corruption of any
            such data.
          </p>

          <h2 id="limitation-of-liability">11. Limitation of Liability</h2>

          <p>
            11.1. In addition to the foregoing, we assume no responsibility for any error, omission,
            interruption, deletion, defect, delay in operation or transmission, communications line
            failure, theft or destruction or unauthorized access to, or alteration of, any Content
            or Services. We are not responsible for any problems or technical malfunction or failure
            of any telephone network or lines, computer online systems or equipment, servers or
            providers, software, failure due to technical problems or traffic congestion on the
            Internet or on the Services. Under no circumstances shall we be responsible for any loss
            or damage, including personal injury or death and any injury or damage to any person's
            mobile device or computer, resulting from use of the Platform, Services, from any
            Content, or from the conduct of any users, whether online or offline. In addition, we
            assume no responsibility for any incorrect data, including Personal Data provided by you
            or on your behalf and you hereby represent and warrant that you are solely responsible
            for any and all data provided to BrightSource, including any incorrect data and you
            shall assume any and all liability for any consequences of provision of such incorrect
            data to us.
          </p>

          <p>
            11.2. IN NO EVENT SHALL BRIGHTSOURCE, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE
            OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY
            FOR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL,
            PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE
            PLATFORM AND/OR SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY
            OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER OR NOT THE
            DAMAGES ARE FORESEEABLE AND WHETHER OR NOT BRIGHTSOURCE HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
            FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR
            CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID US IN THE SIX MONTHS
            IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM OR CAUSE OF ACTION AROSE,
            IF APPLICABLE. IF YOU HAVE NOT MADE ANY PAYMENTS TO BRIGHTSOURCE FOR THE USE OF THE
            SERVICES, THEN BRIGHTSOURCE SHALL NOT HAVE ANY LIABILITY TOWARD YOU.
          </p>

          <h2 id="indemnification">12. Indemnification</h2>

          <p>
            You agree to indemnify, defend, and hold harmless BrightSource, its affiliates, and
            their respective employees, directors, officers, subcontractors and agents, against any
            and all claims, damages, or costs, losses, liabilities or expenses (including reasonable
            court costs and attorneys’ fees) that arise directly or indirectly from: (a) breach of
            these Terms by you or anyone using your computer and/or mobile device, password (whether
            authorized or unauthorized); (b) any claim, loss or damage experienced from your use or
            attempted use of (or inability to use) Platform or Services; (c) your violation of any
            law or regulation or any of your obligations, representations, or warranties hereunder
            including but not limited to breach of any privacy and/or data protection laws and
            regulations to which you are subject; (d) your infringement of any right of any third
            party; and (e) any other matter for which you are responsible hereunder or under
            applicable law.
          </p>

          <h2 id="third-party-content">13. Third-Party Content</h2>

          <p>
            The Platform may provide you with third-party links to websites, applications, and
            services. We make no promises regarding any content, goods or services provided by such
            third parties and all use of third-party websites and applications is at your own risk.
            We do not endorse any products offered by third parties and we urge you to exercise
            caution in using third-party websites or applications.
          </p>

          <h2 id="miscellaneous">14. Miscellaneous</h2>

          <p>
            These Terms shall be governed solely by the laws of the State of Israel, and without
            regard to the United Nations Convention on the International Sales of Goods and the
            competent courts in the State of Israel shall have exclusive jurisdiction to hear any
            disputes arising hereunder. In the event that any provision of these Terms is held to be
            unenforceable, such provision shall be replaced with an enforceable provision which most
            closely achieves the effect of the original provision, and the remaining terms of these
            Terms shall remain in full force and effect. Nothing in these Terms creates any agency,
            employment, joint venture, or partnership relationship between you and BrightSource or
            enables you to act on behalf of BrightSource. Except as may be expressly stated herein,
            these Terms constitute the entire agreement between us and you pertaining to the subject
            matter hereof, and any and all other agreements existing between us and you relating
            thereto are hereby canceled. We may assign and/or transfer our rights and obligations
            hereunder to any third party without prior notice. You shall not assign and/or transfer
            any of your rights or obligations hereunder, and any assignment in violation of the
            foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to
            be a waiver of any preceding or subsequent breach or default. If we are required to
            provide notice to you hereunder, we may provide such notice to the contact details you
            provided upon registration.
          </p>

          <i>Last updated: January 2021</i>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;

const sections = [
  { id: 1, hash: '#use-of-services', text: 'Use of Services' },
  { id: 2, hash: '#account-registration', text: 'Account Registration' },
  {
    id: 3,
    hash: '#suspension-and-termination-of-account',
    text: 'Suspension and Termination of Account',
  },
  { id: 4, hash: '#fees-and-payment', text: 'Fees and Payment' },
  { id: 5, hash: '#content', text: 'Content' },
  { id: 6, hash: '#user-content-restrictions', text: 'User Content Restrictions' },
  { id: 7, hash: '#use-restrictions', text: 'Use Restrictions' },
  { id: 8, hash: '#intellectual-property', text: 'Intellectual Property' },
  { id: 9, hash: '#copyright', text: 'Copyright' },
  {
    id: 10,
    hash: '#disclaimers-and-disclaimer-of-warranty',
    text: 'Disclaimers and Disclaimer of Warranty',
  },
  { id: 11, hash: '#limitation-of-liability', text: 'Limitation of Liability' },
  { id: 12, hash: '#indemnification', text: 'Indemnification' },
  { id: 13, hash: '#third-party-content', text: 'Third-Party Content' },
  { id: 14, hash: '#miscellaneous', text: 'Miscellaneous' },
];
