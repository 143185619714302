function JobIcon(props) {
  const { image, gif, text } = props;

  return (
    <div className="job-icon">
      <img src={image} alt="" className="image" />
      <img src={gif} alt="" className="gif" />
      <span>{text}</span>
    </div>
  );
}

export default JobIcon;
