import { AnchorButton, Button } from '@blueprintjs/core';

import './button.scss';

export function MarketingButton({ children, href = '', ...props }) {
  if (!!href)
    return (
      <AnchorButton href={href} className="marketing-button" {...props}>
        {children}
      </AnchorButton>
    );

  return (
    <Button className="marketing-button" {...props}>
      {children}
    </Button>
  );
}
