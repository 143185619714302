import { InputGroup } from '@blueprintjs/core';
import { ClaimShape } from 'assets/images';
import { MarketingButton } from 'components/common';

import './claimSection.scss';

function ClaimSection() {
  return (
    <section className="claim-section">
      <div className="claim-section-inner">
        <img src={ClaimShape} alt="" />

        <h6>Claim your free employer profile</h6>

        <form>
          <InputGroup required placeholder="Your email" fill large round />
          <MarketingButton type="submit">Sign Up</MarketingButton>
        </form>
      </div>
    </section>
  );
}

export default ClaimSection;
