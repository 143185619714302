import { VARS } from 'config';

export const ROUTE_LIST = [
  {
    label: 'Jobs',
    path: '/jobs',
    href: `${VARS.talentUrl}/jobs`,
  },
  // {
  //   label: 'Salaries',
  //   path: '/salaries',
  //   // requireLogin: true,
  // },
  {
    label: 'Employers',
    path: '/employers',
  },
];
