import { Tag } from '@blueprintjs/core';
import { Searchbox, debounce } from '@brightsource/brightsource-ui-lib';
import { SearchIcon } from 'assets/svg';
import { VARS } from 'config';
import { useLocations, useSearchJob } from 'hooks';
import { useEffect, useState } from 'react';

import './searchJob.scss';

export function SearchJob() {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchLocation, setSearchLocation] = useState(null);

  const debounceSearchChange = debounce((term) => setDebouncedSearchTerm(term), 300);

  const { locations } = useLocations();
  const { jobs } = useSearchJob(debouncedSearchTerm);

  useEffect(() => {
    debounceSearchChange(searchTerm);
  }, [searchTerm, debounceSearchChange]);

  function executeSearch(suggestedClicked, text) {
    const textToSearch = text?.trim().toLowerCase();
    const term = suggestedClicked ? textToSearch : searchTerm;
    const location = searchLocation?.elId || '';

    if (!term) return;

    const url = `${VARS.talentUrl}/job-search?term=${term}&location=${location}&selectedTerm=${suggestedClicked}`;
    window.open(url, '_self');
  }

  return (
    <div className="search-job">
      <div className="search-job-inner container">
        <h6>
          Find <span>Your</span> Next Job
        </h6>

        <Searchbox
          suggestedSerchItems={jobs}
          searchTerm={searchTerm}
          setSearchTerm={(term) => setSearchTerm(term.trim().toLowerCase())}
          suggestedLocations={locations || []}
          searchLocation={searchLocation}
          setSearchLocation={setSearchLocation}
          executeSearch={executeSearch}
          showIcon
        />

        <div className="search-job-bottom">
          <label>Popular Searches</label>

          <div className="search-job-tags">
            {popularSearches.map((keyword, index) => (
              <Tag
                key={index}
                round
                interactive
                intent="primary"
                onClick={() => setSearchTerm(keyword.trim().toLowerCase())}
                icon={<SearchIcon />}
              >
                {keyword}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const popularSearches = [
  'HR Associate',
  'BI',
  'Bookkeeper',
  'Frontend Developer',
  'Data Engineer',
  'Engineering',
  'Sales',
  'Finance',
  'Customer Service',
  'Java Developer',
];
