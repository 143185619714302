import Slider from 'react-slick';

import {
  Company1,
  Company2,
  Company3,
  Company4,
  Company5,
  Company6,
  Company7,
} from 'assets/images';

import './companiesSection.scss';

function CompaniesSection() {
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    autoplay: true,
    pauseOnFocus: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const renderCompanies = () => {
    return companies.map((company) => (
      <div key={company.name} style={{ width: 'auto' }}>
        <img src={company.image} alt="" />
      </div>
    ));
  };

  return (
    <section className="companies-section">
      <h6>Jobs at the hottest companies</h6>

      <Slider {...settings}>{renderCompanies()}</Slider>

      <div className="companies-section-mobile">{renderCompanies()}</div>
    </section>
  );
}

export default CompaniesSection;

const companies = [
  { name: 'audiocodes', image: Company1 },
  { name: 'citi', image: Company2 },
  { name: 'hp', image: Company3 },
  { name: 'lemonade', image: Company4 },
  { name: 'lumenis', image: Company5 },
  { name: 'solaredge', image: Company6 },
  { name: 'vayyar', image: Company7 },
];
