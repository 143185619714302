import { Button } from '@blueprintjs/core';
import { MarketingButton } from 'components/common';
import { VARS } from 'config';
import { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import JobIcon from './JobIcon';

import './jobsSection.scss';

function JobsSection() {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const srcList = categories.map((cat) => cat.image).concat(categories.map((cat) => cat.gif));

    (async () => {
      const promises = srcList.map(
        (src) =>
          new Promise((resolve) => {
            const img = new Image();

            img.src = src;
            img.onload = () => {
              resolve(img);
            };
          }),
      );

      await Promise.all(promises);
    })();
  }, []);

  const moreCategories = categories.slice(10).map((job) => (
    <CSSTransition
      key={job.id}
      // nodeRef={nodeRef}
      timeout={300}
      mountOnEnter
    >
      <JobIcon {...job} />
    </CSSTransition>
  ));

  return (
    <section className="jobs-section">
      <div className="container">
        <h6>All the jobs that suit you gathered in one place</h6>

        <TransitionGroup className="jobs-section-main">
          {categories.slice(0, 10).map((job) => (
            <JobIcon key={job.id} {...job} />
          ))}

          {expanded && moreCategories}
        </TransitionGroup>

        <div className="jobs-section-action">
          <Button minimal onClick={() => setExpanded((x) => !x)}>
            {expanded ? '-' : '+'} Show {expanded ? 'less' : 'more'}
          </Button>

          <MarketingButton href={VARS.talentUrl}>Start hiring now</MarketingButton>
        </div>
      </div>
    </section>
  );
}

export default JobsSection;

const categories = [
  {
    id: 1,
    text: 'Administration',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FAdministration.png?alt=media&token=ab3fa6d5-3d0e-4eff-9e34-409e7468049c',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FAdministration.gif?alt=media&token=f7e475b3-7082-4cec-946a-773d68349f35',
  },
  {
    id: 2,
    text: 'Banking',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FBanking.png?alt=media&token=056e6936-c238-4272-92f9-1ada48380ee9',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FBanking.gif?alt=media&token=d99e8f3f-9235-4c95-a1a7-ff603e3c3694',
  },
  {
    id: 3,
    text: 'Biotech',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FBiotech.png?alt=media&token=fecb405c-932c-4c38-86f4-6dd368f602a7',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FBiotech.gif?alt=media&token=077c4372-e120-47f3-81e1-a33df0d8c09e',
  },
  {
    id: 4,
    text: 'Business',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FBusiness.png?alt=media&token=ed891c74-a74b-46b7-9673-a9872e5b2075',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FBusiness.gif?alt=media&token=c2a3a956-7822-4166-8c57-864598a0f254',
  },
  {
    id: 5,
    text: 'Capital Market',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FCapital%20Market.png?alt=media&token=37d7f0aa-f407-4abb-8789-9672a5c70d03',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FCapital%20Market.gif?alt=media&token=8995de67-8ab1-473b-bc7c-f4941a0f1007',
  },
  {
    id: 6,
    text: 'Data',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FData.png?alt=media&token=54a3065c-ddc6-4daf-9594-eba01f78c65b',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FData.gif?alt=media&token=585d51ec-7918-4b5d-a9e0-5e4717e1b405',
  },
  {
    id: 7,
    text: 'Design',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FDesign.png?alt=media&token=2a85b105-bae2-4edc-b340-91b7f42abe62',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FDesign.gif?alt=media&token=2474123b-ce8b-4578-bd77-64fdaf9d053e',
  },
  {
    id: 8,
    text: 'Engineering',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FEngineering.png?alt=media&token=b236d384-59ce-429e-8927-1e7d310fdd36',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FEngineering.gif?alt=media&token=609011c3-8785-46ea-ae20-1a42749c8095',
  },
  {
    id: 9,
    text: 'Executive',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FExecutive.png?alt=media&token=82d7c023-455c-435f-8752-ce051759a31b',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FExecutive.gif?alt=media&token=d7758680-c7a2-4def-866d-57d20516b157',
  },
  {
    id: 10,
    text: 'Finances',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FFinances.png?alt=media&token=63647bc8-39ff-46dc-a595-9c5f83842e3a',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FFinances.gif?alt=media&token=5d35caa8-e9ca-4405-8ffd-0972f62abe3d',
  },
  {
    id: 11,
    text: 'Hardware',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FHardware.png?alt=media&token=bf739380-c889-4ac9-9173-e0841dc0aae6',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FHardware.gif?alt=media&token=d079c758-c327-4f30-accc-453cf0a96b78',
  },
  {
    id: 12,
    text: 'HR',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FHR.png?alt=media&token=166d001c-0421-4a02-b317-66e3c241671b',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FHR.gif?alt=media&token=afeb3f48-f34b-489d-8847-36d3cdeb2df1',
  },
  {
    id: 13,
    text: 'Information',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FInformation.png?alt=media&token=9463fbf3-40ae-46f6-9730-64ad2a45c31a',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FInformation.gif?alt=media&token=0106f3ce-3304-4164-9e2d-7aa5975d8ad6',
  },
  {
    id: 14,
    text: 'Information Systems',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FInformation%20Systems.png?alt=media&token=966e8dac-4f25-43ba-bd91-2eebb5f468fb',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FInformation%20Systems.gif?alt=media&token=482b4ffe-6e72-4aa5-9a8c-9dff11bacc95',
  },
  {
    id: 15,
    text: 'Insurance',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FInsurance.png?alt=media&token=42746122-b13a-4ff6-aa68-04665974bcf9',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FInsurance.gif?alt=media&token=e94891d4-2829-430f-9216-20f6a6a28de8',
  },
  {
    id: 16,
    text: 'Law',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FLaw.png?alt=media&token=5111201d-b9bf-4480-940e-a9a15dbe3001',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FLaw.gif?alt=media&token=7eeacf80-b947-4acf-8d01-936c887d1aa0',
  },
  {
    id: 17,
    text: 'Marketing',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FMarketing.png?alt=media&token=f8d391f5-3af9-4079-85fa-f476763bf2ed',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FMarketing.gif?alt=media&token=df95885e-02e5-4a32-830b-24d65c3d70cc',
  },
  {
    id: 18,
    text: 'Operations',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FOperations.png?alt=media&token=97d49c12-f025-4872-b960-f39f8159bbf8',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FOperations.gif?alt=media&token=8d670083-e43b-4022-aecf-c1a2973d1411',
  },
  {
    id: 19,
    text: 'Pharma',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FPharma.png?alt=media&token=c42146d6-2b64-443b-bfe2-1e449de3e9f4',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FPharma.gif?alt=media&token=83f91769-f96d-463f-a1bb-17f7452b014f',
  },
  {
    id: 20,
    text: 'Procurement',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FProcurement.png?alt=media&token=51e42768-0427-41c9-bfae-4c0f008c5cb7',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FProcurement.gif?alt=media&token=a56e53bb-6fa7-4e92-92c1-01f17deef9e8',
  },
  {
    id: 21,
    text: 'Production',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FProduction.png?alt=media&token=d1fa328b-4af7-44f3-88e8-a933102fb641',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FProduction.gif?alt=media&token=8560487d-fb6a-43c1-bad7-d9455467c883',
  },
  {
    id: 22,
    text: 'Product Management',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FProduct%20Management.png?alt=media&token=7bf91350-63e4-4530-bf0a-68177c92baf8',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FProduct%20Management.gif?alt=media&token=a2557dbb-eaaa-4b14-a045-3b671758de3b',
  },
  {
    id: 23,
    text: 'Projects',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FProjects.png?alt=media&token=a2bcb334-5cf0-42f7-9348-3e61122aafbf',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FProjects.gif?alt=media&token=f3ab35cf-34b5-4088-a26f-3e0892894299',
  },
  {
    id: 24,
    text: 'QA',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FQA.png?alt=media&token=b44e5168-66e2-4dcb-91df-cbbc391c5f2b',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FQA.gif?alt=media&token=2e14d696-a773-4a72-9552-7c7ddb262828',
  },
  {
    id: 25,
    text: 'R&D',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FR%26D.png?alt=media&token=5e72675d-d79a-48e3-b97b-a4ca32ac515b',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FRD.gif?alt=media&token=bf8805c0-60f8-4280-8df5-6f4f65c2f8e9',
  },
  {
    id: 26,
    text: 'Sales',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FSales.png?alt=media&token=7552b4fd-a5b2-435b-acdc-da74ad6aee8c',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FSales.gif?alt=media&token=3450d67a-e19c-45dd-9afb-240465e41b24',
  },
  {
    id: 27,
    text: 'Science',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FScience.png?alt=media&token=6deb16ec-f8ac-46ae-8a0a-9e8d62a57532',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FScience.gif?alt=media&token=df9f7893-3f62-46c0-8a34-1b73d16b26df',
  },
  {
    id: 28,
    text: 'Software',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FSoftware.png?alt=media&token=8c024945-0bfa-49ad-ad78-cbbd844df030',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FSoftware.gif?alt=media&token=07f25c41-e4e8-4c46-bb16-73045b8b0fdf',
  },
  {
    id: 29,
    text: 'Staff',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FStaff.png?alt=media&token=2877d5b3-ae51-4f41-80e0-8c03824df12f',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FStaff.gif?alt=media&token=bdae3d72-2fa8-45ab-b797-ff95b817ef39',
  },
  {
    id: 30,
    text: 'Support',
    image:
      'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fstatic-icons%2FSupport.png?alt=media&token=0ac19d9f-cb2e-4000-958f-2546e4f65006',
    gif: 'https://firebasestorage.googleapis.com/v0/b/talent-test1.appspot.com/o/guest-home-page%2Fsection6%2Fanimated-icons%2FSupport.gif?alt=media&token=9a0bd4ce-6a86-43e0-9f82-0c05b0f27f3d',
  },
];
