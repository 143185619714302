import { Logo } from 'assets/svg';
import cn from 'classnames';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ROUTE_LIST } from './routes';

function HeaderMobile() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    if (isOpen) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }

    setIsOpen((x) => !x);
  };

  return (
    <header className="header-mobile">
      <div className="header-container">
        <Link to="/" className="header-logo" onClick={() => setIsOpen(false)}>
          <Logo />
        </Link>

        <div className={cn('header-links', { active: isOpen })}>
          {ROUTE_LIST.map((route) => {
            if (route.href) {
              return (
                <a key={route.path} href={route.href}>
                  {route.label}
                </a>
              );
            }

            return (
              <NavLink key={route.path} to={route.path} onClick={() => setIsOpen(false)}>
                {route.label}
              </NavLink>
            );
          })}

          <Link to="/">Log in</Link>
        </div>

        <div className="header-actions">
          <div className={cn('hamburger', { active: isOpen })} onClick={handleToggle}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderMobile;
