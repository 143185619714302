import { Button } from '@blueprintjs/core';
import { FeaturedGif1 } from 'assets/images';
import cn from 'classnames';

import './featuredItem.scss';
import { MarketingButton } from 'components/common';

function FeaturedItem({
  title = '',
  subtitle = '',
  description = '',
  imgSrc = FeaturedGif1,
  buttonText,
  reverse = false,
  isEmployer = false,
  children,
}) {
  return (
    <div className={cn('featured-item', { reverse, isEmployer })}>
      <div className="featured-item-content">
        <h3>{title}</h3>
        {subtitle && <h6>{subtitle}</h6>}
        <p>{description}</p>

        {children}

        {buttonText && (
          <div className="featured-item-action">
            {isEmployer ? (
              <MarketingButton>{buttonText}</MarketingButton>
            ) : (
              <Button intent="primary">{buttonText}</Button>
            )}
          </div>
        )}
      </div>

      <div className="featured-item-image">
        <img src={imgSrc} alt="" />
      </div>
    </div>
  );
}

export default FeaturedItem;
