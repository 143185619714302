import { FeaturedShape, FeaturedGif1, FeaturedGif2, FeaturedGif3 } from 'assets/images';
import FeaturedItem from 'components/featured-item';

function TalentFeatured() {
  return (
    <section className="talent-featured">
      <div className="talent-featured-inner container">
        <FeaturedItem
          title="You do you. Your profile should too."
          description="Create a profile that highlights your skills and preferences, then apply anywhere with
            one click."
          buttonText="Create Profile"
          imgSrc={FeaturedGif1}
        />

        <FeaturedItem
          reverse
          title="Find work that works for you."
          description="A personalized - and completely private - job search, with all the info you care about
        upfront."
          buttonText="Browse jobs"
          imgSrc={FeaturedGif2}
        />

        <FeaturedItem
          title="Dreams do come true!"
          description="Companies can find you and pitch their opportunity. Connect directly with founders and
        recruiters, without the middlemen."
          buttonText="Create Profile"
          imgSrc={FeaturedGif3}
        />
      </div>

      <div className="talent-featured-bottom">
        <img src={FeaturedShape} alt="" />
      </div>
    </section>
  );
}

export default TalentFeatured;
