import ClaimSection from 'components/claim-section';
import CompaniesSection from 'components/companies-section';
import HeroSection from 'components/hero-section';
import JobsSection from 'components/jobs-section';
import TalentFeatured from './TalentFeatured';

import './talent.scss';

function TalentPage() {
  return (
    <div className="talent-page">
      <HeroSection
        title="Seeking a new tech role or hiring?"
        description="Join BrightSource and connect instantly!"
      />

      <CompaniesSection />
      <TalentFeatured />
      <JobsSection />
      <ClaimSection />
    </div>
  );
}

export default TalentPage;
