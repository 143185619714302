import { FocusStyleManager } from '@blueprintjs/core';
import { Footer, Header } from 'components/common';
import EmployerPage from 'pages/employer';
import NotFound from 'pages/not-found';
import PrivacyPolicy from 'pages/privacy-policy';
import TalentPage from 'pages/talent';
import TermsOfUse from 'pages/terms-of-use';
import { Route, Routes } from 'react-router-dom';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import 'styles/app.scss';

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  return (
    <div className="main-frame">
      <Header />

      <main>
        <Routes>
          <Route path="/" element={<TalentPage />} />
          <Route path="/employers" element={<EmployerPage />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
