import { FacebookIcon, LinkedinIcon, LogoLight, TwitterIcon, YoutubeIcon } from 'assets/svg';
import { Link } from 'react-router-dom';

import './footer.scss';

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer-inner container">
        <div className="footer-main">
          <Link to="/" className="footer-logo">
            <LogoLight />
          </Link>

          <div className="footer-links">
            <Link to="/">Startup jobs</Link>
            {/* <Link to="/">Recruit</Link> */}
            {/* <Link to="/">Invest</Link>
            <Link to="/">Blog</Link> */}
            <Link to="/terms-of-use">Terms of use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

          <div className="footer-socials">
            <a className="disabled" href="/">
              <FacebookIcon />
            </a>
            <a href="https://il.linkedin.com/company/brightsource-data-analytics">
              <LinkedinIcon />
            </a>
            <a className="disabled" href="/">
              <TwitterIcon />
            </a>
            <a className="disabled" href="/">
              <YoutubeIcon />
            </a>
          </div>
        </div>

        <p className="footer-copyright">Copyright © 2023. All rights reserved.</p>
      </div>
    </footer>
  );
}
