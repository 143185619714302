import { FeaturedGif4, FeaturedGif5, FeaturedGif6 } from 'assets/images';
import { LeafBlue, LeafRed, LeafYellow } from 'assets/svg';
import FeaturedItem from 'components/featured-item';

function EmployerFeatured() {
  return (
    <section className="employer-featured">
      <div className="employer-featured-inner container">
        <FeaturedItem
          isEmployer
          reverse
          title="Scale your team"
          description="8 million startup-ready candidates"
          buttonText="Find talents"
          imgSrc={FeaturedGif4}
        >
          <div className="featured-item-group">
            <div className="featured-item-row">
              <LeafYellow />
              <div>
                <label>Top tech talent</label>
                <p>Engineers, PMs, designers, sales, & marketing</p>
              </div>
            </div>

            <div className="featured-item-row">
              <LeafRed />
              <div>
                <label>Worldwide focus</label>
                <p>Local & remote candidates in the US and abroad</p>
              </div>
            </div>

            <div className="featured-item-row">
              <LeafBlue />
              <div>
                <label>Startup-ready</label>
                <p>Candidates on our platform are specifically interested in startups</p>
              </div>
            </div>
          </div>
        </FeaturedItem>

        <FeaturedItem
          isEmployer
          title="We’ve improved every aspect of hiring"
          description="Discover jobs before everyone else and follow the recruitment process online easily and transparently until you find the job of your dreams."
          buttonText="Find talents"
          imgSrc={FeaturedGif5}
        />

        <FeaturedItem
          isEmployer
          reverse
          title="Tune in together"
          subtitle="Collaborate Effortlessly"
          description="Reviewing as a team is as simple as pressing play. Getting to know candidates is now more enjoyable than ever."
          imgSrc={FeaturedGif6}
        >
          <div className="featured-item-group without-label">
            <div className="featured-item-row">
              <label>Review Together</label>
            </div>

            <div className="featured-item-row">
              <LeafYellow />
              <p>Create shortlists that are easy to share.</p>
            </div>
            <div className="featured-item-row">
              <LeafRed />
              <p>Introduce candidates to hiring managers to keep everyone on the same page.</p>
            </div>
            <div className="featured-item-row">
              <LeafBlue />
              <p>
                Invite hiring managers, co-workers or clients to get to know candidates early in the
                process.
              </p>
            </div>
          </div>
        </FeaturedItem>
      </div>
    </section>
  );
}

export default EmployerFeatured;
