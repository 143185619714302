import { HeroShape } from 'assets/images';
import { Leaf1, Leaf2, Leaf3, Leaf4, Leaf5 } from 'assets/svg';
import cn from 'classnames';
import { LeafStep, MarketingButton, SearchJob } from 'components/common';
import { VARS } from 'config';

import './heroSection.scss';

function HeroSection(props) {
  const { title, subtitle, description, isEmployer = false } = props;

  return (
    <section className={cn('hero-section', { isEmployer })}>
      {!isEmployer && <SearchJob />}

      <div className="hero-section-inner container">
        <div className="hero-section-content">
          {/* {subtitle && <span>{subtitle}</span>} */}

          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <p>{description}</p>

          {isEmployer && <MarketingButton href={VARS.talentUrl}>Start hiring now</MarketingButton>}
        </div>

        <div className="hero-section-image">
          <img src={HeroShape} alt="" />
        </div>
      </div>

      {isEmployer && (
        <div className="hero-section-grid container">
          <LeafStep
            icon={Leaf1}
            heading="Job Requirement"
            description="Build a job description for to be filled"
          />
          <LeafStep
            icon={Leaf2}
            heading="Talent Sourcing"
            description="Make it public about the vacancy that exists"
          />
          <LeafStep
            icon={Leaf3}
            heading="Interview & Selection"
            description="Shortlist the successful"
          />
          <LeafStep
            icon={Leaf4}
            heading="Job Requirement"
            description="Build a job description for to be filled"
          />
          <LeafStep
            icon={Leaf5}
            heading="Final Placement"
            description="Employ staff and make placement"
          />
        </div>
      )}
    </section>
  );
}

export default HeroSection;
